import { EnvironmentConfig } from "../app/shared/models/config/environment-config.model";

const api_key = 'WUJYeFhTMlhTc3ZIQWxxWTI3SVJwU09ZN0wrczNqMjR5MzJPOFIxZGtFeE1QVlU4QXJlMVZzL0JRaDBNVWlyV2tZMnBrcDFDUFNnWHV5YWR6V2d5TGowbk5YenNCQVhya1BDZXpmNDJkUTA9';
const key_sig = 'C605E98B-3804-4DC5-B406-159BDF031B6B';

export const environment: EnvironmentConfig = {
  production: false,
  allowMock: true,
  contacts: [
    { key: 'b2b', phone: '1-877-876-0827' },
    { key: 'b2c', phone: '1-800-876-6827' }
  ],
  clients: [
    {
      name: 'events',
      url: 'https://dev-events.nna-platform.com',
      prefix: 'api/v1',
      authType: 'key-sig',
      authKey: api_key,
      authSecret: key_sig,
    },
    {
      name: 'orders',
      url: 'https://dev-orders.nna-platform.com',
      prefix: 'api/v1',
      authType: 'key-sig',
      authKey: api_key,
      authSecret: key_sig,
    }
  ]
}
